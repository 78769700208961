import './Home.css';
import connectHero from '../../assets/Connect-hero.png';
import greatHarvestLogo from '../../assets/Great Harvest Logo-white.png';
import burtBrosLogo from '../../assets/BurtBrothers Logo-white.png';
import utahStateLogo from '../../assets/Utah State Logo.png';
import smokeNBonesLogo from '../../assets/smokin-full-logo-white.png';
import spankysLogo from '../../assets/Spankys-Logo-white.png';
import bigBrosLogo from '../../assets/Big Brothers Logo-white.png';
import bountifulBikeLogo from '../../assets/bb logo-white.png';
import tetonToysLogo from '../../assets/Teton Toys Logo-white.png';
import lakeSideLogo from '../../assets/Lakeside Logo.png';
import stringhamLumberLogo from '../../assets/strighamlumber LOGO WHITE.png';
import southDavisRecLogo from '../../assets/South Davis Rec-white.png';
import feltLightingLogo from '../../assets/Felt Lighting Logo-white.png';
import locationImage1 from '../../assets/Location-image-1.png';
import locationImage2 from '../../assets/Location-image-2.png';
import targetedIcon from '../../assets/Icon-target.png';
import thumbsUpIcon from '../../assets/Icon-thumbsup.png';
import receiptIcon from '../../assets/Icon-receipt.png';
import womanImage from '../../assets/Photo-circle-woman-blue.png'
import digiviewLogo from '../../assets/digiView_Logo_Color__Light_Horizontal-new.png';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

export default function Home() {
    const [activeSlide, setActiveSlide] = useState(1);

    useEffect(() => {
        let current = 1;
        const cycleReviews = () => {
            if (current === 3) {
                current = 1;
            } else {
                current += 1;
            }
            setActiveSlide(current);
        };
        const intervalId = setInterval(() => {
            cycleReviews();
        }, 6000);
        return () => clearInterval(intervalId);
    }, []);

    const reviews = [
        {
            quote: <p className='quote-text'>"digiView's indoor billboard network has not only helped build brand awareness for Dash Auto Services, but has also generated new customers for us! <span className='yellow-highlight'>They are a great company to work with</span> and I am thrilled with the results."</p>,
            author: "Keaton Hyde, Owner",
            company: "Dash Auto Services",
        },
        {
            quote: <p className='quote-text'>"digiView made it so easy to get our message in front of multiple audiences. They listened to what we wanted and then created professional and visually stunning graphics for our ad. They were always quick to reply and <span className='yellow-highlight'>a pleasure to work with!</span>"</p>,
            author: "Alison Amann, Marketing Manager",
            company: "Big Brothers Big Sisters of Utah",
        },
        {
            quote: <p className='quote-text'>"Getting the word out about Spanky's reopening was critical and <span className='yellow-highlight'>digiView was a huge help.</span> I have had several people tell me they didn't know Spanky's was open again until they saw my ad on one of digiView's screens."</p>,
            author: "Chris Holgreen, Owner",
            company: "Spanky's Deli",
        }
    ];

    return (
        <div className='home-container'>
            <Helmet>
                {/* Google Analytics */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-LKZLK9CCHH"></script>
                <script>
                    {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-LKZLK9CCHH');
          `}
                </script>
            </Helmet>
            <div className='connect-container'>
                <div className='connect-top-text-and-image'>
                    <div className='connect-text'>
                        <p className='connect-large-text'>Connect <span className='no-wrap'>with <span className='blue-highlight'>your</span></span> customers</p>
                        <p className='connect-smaller-text'>through the power of our indoor digital billboard network—where they work, shop, eat, and play.</p>
                        <Link to='/advertising'>
                            <button className='advertise-button'>Advertise with us</button>
                        </Link>
                    </div>
                    <div className='connect-image'>
                        <img src={connectHero} alt='connection' className='connect-hero'/>
                    </div>
                </div>
                <div className='connect-local-customers'>
                    <p className='connect-customers-text'>Some of our local customers:</p>
                    <div className='connect-customers-logos-row-1'>
                        <img src={greatHarvestLogo} alt='great harvest logo' className='customer-logo' />
                        <img src={burtBrosLogo} alt='burt brothers logo' className='customer-logo' />
                        <img src={utahStateLogo} alt='utah state logo' className='customer-logo' />
                        <img src={smokeNBonesLogo} alt='smokin bones logo' className='customer-logo' />
                        <img src={spankysLogo} alt='spankys logo' className='customer-logo' />
                        <img src={bigBrosLogo} alt='big brothers big sisters logo' className='customer-logo' />
                        <img src={bountifulBikeLogo} alt='boutiful bicycle logo' className='customer-logo' />
                    </div>
                    <div className='connect-customers-logos-row-2'>
                        <img src={tetonToysLogo} alt='teton toys logo' className='customer-logo' />
                        <img src={lakeSideLogo} alt='lakeside logo' className='customer-logo' />
                        <img src={stringhamLumberLogo} alt='stringham lumber logo' className='customer-logo' />
                        <img src={southDavisRecLogo} alt='south davis recreation logo' className='customer-logo' />
                        <img src={feltLightingLogo} alt='felt lighting logo' className='customer-logo' />
                    </div>
                </div>
            </div>
            <div className='effective-container'>
                <div className='effective-text'>
                    <p className='effective-large-text'>Effective. Targeted. <span className='yellow-highlight'>Local.</span></p>
                    <p className='effective-smaller-text'>The digiView™ network of indoor digital billboards puts your ad in front of local customers who work, shop, eat, and play right where you are. That’s about as local as you can get! And if you qualify to host one of our indoor digital billboards you receive even more exposure both in and outside of your business!</p>
                    <Link to='/hosting'>
                        <button className='host-partner-button'>Learn more about becoming a host partner</button>
                    </Link>
                </div>
                <div className='effective-images'>
                    <div className="image-stack">
                        <div className="image-stack__item image-stack__item--top">
                            <img className='stack-test-top' src={locationImage2} alt="location two" />
                        </div>
                        <div className="image-stack__item image-stack__item--bottom">
                            <img className='stack-test-bottom' src={locationImage1} alt="location one" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='easy-container'>
                <div className='easy-text'>
                    <p className='easy-large-text'>digiView makes local advertising easy</p>
                </div>
                <div className='easy-cards-container'>
                    <div className='easy-card'>
                        <img src={targetedIcon} alt='targeted' className='card-icon'/>
                        <p className='easy-card-title'>Targeted</p>
                        <p className='easy-card-subtitle'>Your advertisement is seen by the heart of your target audience where they work, shop, eat and play. No other medium is as focused as what we offer.</p>
                    </div>
                    <div className='easy-card'>
                        <img src={thumbsUpIcon} alt='thumbs up' className='card-icon' />
                        <p className='easy-card-title'>Relevant</p>
                        <p className='easy-card-subtitle'>Advertisements are created and updated each quarter at NO COST to you! Keeping your marketing message fresh and relevant.</p>
                    </div>
                    <div className='easy-card'>
                        <img src={receiptIcon} alt='receipt' className='card-icon' />
                        <p className='easy-card-title'>Affordable</p>
                        <p className='easy-card-subtitle'>Packages start at just $400 per month and generate thousands of impressions—extending your marketing budget further.</p>
                    </div>
                </div>
                <Link to='/advertising'>
                    <button className='learn-more-button'>Learn more</button>
                </Link>
            </div>
            <div className='win-container'>
                <div className='win-text'>
                    <p className='win-large-text'>Win! Win!</p>
                    <p className='win-smaller-text'>At digiView, we succeed when our customers succeed. We provide two ways of partnering with our customers to ensure the right fit for your needs.</p>
                </div>
                <div className='win-cards-container'>
                    <div className="win-card-stack">
                        <div className="win-card-stack__item win-card-stack__item--top">
                            <div className='win-card-content'>
                                <p className='win-card-title'>As a Host</p>
                                <Link to='/hosting'>
                                    <button className='win-card-button-left'>Start Hosting Now</button>
                                </Link>
                                <p className='win-card-text'>Join our network of Hosts with a screen professionally installed at your business location.</p>
                            </div>
                        </div>
                        <div className="win-card-stack__item win-card-stack__item--bottom">
                            <div className='win-card-content'>
                                <p className='win-card-title'>As an Advertiser</p>
                                <Link to='/advertising'>
                                    <button className='win-card-button-right'>Start Advertising Now</button>
                                </Link>
                                <p className='win-card-text'>Use your existing advertisements or use our team of creative professionals who will design your advertisements for you at NO COST!</p>
                            </div>
                        </div>
                        <div className='win-card-stack__item win-card-stack__item--middle'>
                            <img src={womanImage} alt='woman' className='win-image'/>
                        </div>
                    </div>
                </div>
                <div className='win-quotes-container'>
                    <ul className="carousel__list">
                        {reviews.map((review, index) => {
                            const { company, author, quote } = review;
                            const count = index + 1;
                            return (
                                <li
                                    className={
                                        `carousel__item
                                        ${count === activeSlide ? " active" : ""}
                                        ${count < activeSlide ? " left" : ""}
                                        ${count > activeSlide ? " right" : ""}`
                                    }
                                    key={count}
                                >
                                    <blockquote className="carousel__quote">
                                        {quote}
                                        <cite>
                                            <span className="carousel__author">&ndash; {author}</span>
                                            <span className="carousel__company">{company}</span>
                                        </cite>
                                    </blockquote>
                                </li>
                            );
                        })}
                        <li className="carousel__indicator">
                            <span
                                className={`carousel__dot${activeSlide === 1 ? " active" : ""}`}
                            />
                            <span
                                className={`carousel__dot${activeSlide === 2 ? " active" : ""}`}
                            />
                            <span
                                className={`carousel__dot${activeSlide === 3 ? " active" : ""}`}
                            />
                        </li>
                    </ul>
                </div>
                <img src={digiviewLogo} alt='digiview logo' className='digiview-footer-logo' />
            </div>
        </div>
    )
}