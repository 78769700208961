import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import digiviewLogo from '../../assets/digiView_Logo_Color__Light_Horizontal-new.png';
import './ContactUs.css';
import { Helmet } from 'react-helmet';

export default function ContactUs() {
  const form = useRef();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_as1hdck', 'template_e3nbeb6', form.current, 'IEmI2Y1Hu4BQsMJEQ')
      .then((result) => {
        console.log(result.text);
        setIsFormSubmitted(true); // Form submitted successfully
      })
      .catch((error) => {
        console.log(error.text);
      });
  };

  return (
    <div className='contact-us-container'>
      <Helmet>
                {/* Google Analytics */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-LKZLK9CCHH"></script>
                <script>
                    {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-LKZLK9CCHH');
          `}
                </script>
            </Helmet>
      <div className='work-together-container'>
        <div className='work-together-text-and-chat'>
          <p className='work-together-large-text'>Let’s Work Together</p>
        </div>
        <div className='work-together-text-container'>
          <p className='work-together-smaller-text'>digiView Marketing is part of a nation-wide organization that has a network of 4,000+ indoor digital billboards. Our network enables businesses to access their local target audience in a new and effective way and digiView is currently focused on growing the network with businesses in Salt Lake and Davis counties.</p>
          <p className='work-together-smaller-text'>Call, chat, email, or send us a message using the form below and we’ll be in touch. We can’t wait to partner with you to help you reach your customers.</p>
        </div>
        <div className='contact-info-container'>
          <p className='contact-info-text'>Call: (385) 258-3211</p>
          <p className='contact-info-text'>Email: blake@digiviewmarketing.com</p>
        </div>
      </div>

      {!isFormSubmitted ? (
        <div className='contact-us-form-container'>
          <div className='contact-card'>
            <p className='send-message-text'>Send us a message</p>
            <form ref={form} onSubmit={sendEmail} className='form-content'>
              <span className='form-field'>
                <label>Your Name:</label>
                <input type="text" name="from_name" required/>
              </span>
              <span className='form-field'>
                <label>Business Name:</label>
                <input type="text" name="company" required/>
              </span>
              <span className='form-field'>
                <label>Phone:</label>
                <input type="phone" name="phone" required/>
              </span>
              <span className='form-field'>
                <label>Email:</label>
                <input type="email" name="email" required/>
              </span>
              <span className='form-field'>
                <label>Message (optional):</label>
                <textarea name="message" rows='8' />
              </span>
              <span className='submit-button-container'>
                <input type="submit" value="Send to Digiview" className='send-to-digiview-button' />
              </span>
            </form>
          </div>
        </div>
      ) : (
        <div id="successMessage" className='contact-us-form-container'>
          <div className='contact-card'>
            <h2>Success! We will be in contact with you shortly.</h2>
          </div>
        </div>
      )}

      <div className='contact-page-logo'>
        <img src={digiviewLogo} alt='digiview logo' className='contact-digiview-logo-footer' />
      </div>
    </div>
  );
}
