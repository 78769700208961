import './DigitalMarketingServices.css';
import { Link } from 'react-router-dom';
// import { FaQuestionCircle } from 'react-icons/fa';
import digiviewLogo from '../../assets/digiView_Logo_Color__Light_Horizontal-new.png';
import geofencingImg from '../../assets/DMS-Geofencing.png';
import googleBusinessImg from '../../assets/DMS-Google_Management.png';
import socialMediaImg from '../../assets/DMS-Social_Media_Management.png';
import webDesignImg from '../../assets/DMS-Websites.png';
import digitalMenuImg from '../../assets/DMS-Menu_Boards.png';
import designServicesImg from '../../assets/DMS-Design_Services.png';
import { Helmet } from 'react-helmet';

export default function DigitalMarketingServices() {
  return (
    <div className='digital-marketing-services-container'>
      <Helmet>
                {/* Google Analytics */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-LKZLK9CCHH"></script>
                <script>
                    {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-LKZLK9CCHH');
          `}
                </script>
            </Helmet>
      <div className='dm-services-container'>
        <div className='dm-services-text-and-chat'>
          <p className='dm-services-large-text'>Digital Marketing Services</p>
          {/* TODO: link up chat stuff here */}
          {/* <button className='dm-chat-button'>
            <span>Chat</span>
            <FaQuestionCircle />
          </button> */}
        </div>
        <div className='dm-services-text'>
          <p className='dm-services-smaller-text'>digiView offers a full range of services to power your digital marketing strategy.</p>
        </div>
      </div>
      <div className='dm-services-list-container'>
        <div className='dm-service-container' id='geofencing'>
          <img src={geofencingImg} alt='woman using phone' className='dm-list-image' />
          <div className='dm-service'>
            <p className='dm-service-title'>Geofencing Campaigns</p>
            <p className='dm-service-text'>Target mobile users with pinpoint accuracy. Our campaigns bring more customers to your business. Display Call to Action advertisements across more than 100k apps and track conversions!</p>
            <Link to='/contact-us'>
              <button className='dm-learn-more-button'>Learn More</button>
            </Link>
          </div>
        </div>
        <div className='dm-service-container' id='google-business'>
          <div className='dm-service'>
            <p className='dm-service-title'>Google Business Profile Management</p>
            <p className='dm-service-text'>Our team will work to do the following:</p>
            <ul className='dm-service-list-container'>
              <li className='dm-service-list'>Improve your ranking on the world’s most popular search engine.</li>
              <li className='dm-service-list'>Increase your visibility on Google by uploading photos every month, responding to customer reviews, and regular postings utilizing relevant keywords.</li>
              <li className='dm-service-list'>Correct citations across the most frequented online directories.</li>
            </ul>
            <Link to='/contact-us'>
              <button className='dm-learn-more-button'>Learn More</button>
            </Link>
          </div>
          <img src={googleBusinessImg} alt='google business' className='dm-list-image' />
        </div>
        <div className='dm-service-container' id='social-media'>
          <img src={socialMediaImg} alt='social media' className='dm-list-image' />
          <div className='dm-service'>
            <p className='dm-service-title'>Social Media Management</p>
            {/* TODO: add in correct text here */}
            <p className='dm-service-text'>Our team will do all they can to improve the performance, engagement, and audience reach for your social media profiles keeping them current, fresh, and with clear brand messaging.  These services coupled with quarterly performance reports will not only help build your social media presence, but enable you to fine tune and direct your messaging to provide the best results possible!</p>
            <Link to='/contact-us'>
              <button className='dm-learn-more-button'>Learn More</button>
            </Link>
          </div>
        </div>
        <div className='dm-service-container' id='digital-menu'>
          <div className='dm-service'>
            <p className='dm-service-title'>Digital Menu Boards</p>
            <ul className='dm-service-list-container'>
              <li className='dm-service-list'>Instantly update, change, or schedule eye catching digital menus.</li>
              <li className='dm-service-list'>Highlight specials or lesser known items</li>
              <li className='dm-service-list'>Upsell customers with special offers</li>
              <li className='dm-service-list'>Promote upcoming events</li>
            </ul>
            <Link to='/contact-us'>
                <button className='dm-learn-more-button'>Learn More</button>
            </Link>
          </div>
          <img src={digitalMenuImg} alt='digital menu boards' className='dm-list-image' />
        </div>
        <div className='dm-service-container' id='web-design'>
          <img src={webDesignImg} alt='web design' className='dm-list-image' />
          <div className='dm-service'>
            <p className='dm-service-title'>Website Design + Management</p>
            <p className='dm-service-text'>Every business needs a website. Whether you’re just starting out or your website hasn’t been updated in years, we can build a custom website to meet your needs and the needs of your customers. Upgrade to a state of the art, mobile-friendly website for your business. Custom designed to engage your customers and power your brand.</p>
            <Link to='/contact-us'>
              <button className='dm-learn-more-button'>Learn More</button>
            </Link>
          </div>
        </div>
        <div className='dm-service-container' id='graphic-design'>
          <div className='dm-service'>
            <p className='dm-service-title'>Design Services</p>
            <p className='dm-service-text'>Our professional creative team will create brand assets that will inspire, inform and captivate customers.</p>
            <ul className='dm-service-list-container'>
              <li className='dm-service-list'>Logo design</li>
              <li className='dm-service-list'>Banners and covers</li>
              <li className='dm-service-list'>Flyers</li>
              <li className='dm-service-list'>Print media</li>
            </ul>
            <Link to='/contact-us'>
              <button className='dm-learn-more-button'>Learn More</button>
            </Link>
          </div>
          <img src={designServicesImg} alt='design services' className='dm-list-image' />
        </div>
      </div>
      <div className='dms-page-logo'>
        <img src={digiviewLogo} alt='digiview logo' className='dms-digiview-footer-logo' />
      </div>
    </div>
  )
}