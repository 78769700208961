import { Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Hosting from './components/hosting/Hosting';
import Advertising from './components/advertising/Advertising';
import DigitalMarketingServices from './components/digital-marketing-services/DigitalMarketingServices';
import ScreenLocations from './components/screen-locations/ScreenLocations';
import ContactUs from './components/contact-us/ContactUs';
import Home from './components/home/Home';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className='page-wrapper'>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="hosting" element={<Hosting />} />
          <Route path="advertising" element={<Advertising />} />
          <Route path="digital-marketing-services" element={<DigitalMarketingServices />} />
          <Route path="screen-locations" element={<ScreenLocations />} />
          <Route path="contact-us" element={<ContactUs />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
}

export default App;
