import './Footer.css';
import { BiCopyright } from 'react-icons/bi';

export default function Footer() {
    return (
        <div className='footer'>
            <div className='copyright-statement'>
                <span className='copyright-logo'>
                    <BiCopyright />
                </span>
                <p>2023 digiView Marketing, LLC. All rights reserved.</p>
            </div>
            {/* TODO: link to privacy policy */}
            {/* <a href='#' className='privacy-policy-link'>Privacy Policy</a> */}
        </div>
    )
}