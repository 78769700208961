import './Advertising.css';
import { Link } from 'react-router-dom';
// import { FaQuestionCircle } from 'react-icons/fa';
import locationImg from '../../assets/Ad-photo-circle.png';
import demoImg from '../../assets/Demo-image-tv-large-bbbs.png';
import audienceIcon from '../../assets/Icon-Audience.png';
import receiptIcon from '../../assets/Icon-receipt.png';
import targetedIcon from '../../assets/Icon-target.png';
import thumbsUpIcon from '../../assets/Icon-thumbsup.png';
import locationIcon from '../../assets/Icon-Map Pin.png';
import supportIcon from '../../assets/Icon-Support.png';
import digiviewLogo from '../../assets/digiView_Logo_Color__Light_Horizontal-new.png';

export default function Advertising() {
    return (
        <div className='advertising-container'>
            <div className='advertise-digiview-container'>
                <div className='advertise-digiview-text-and-chat'>
                    <p className='advertise-digiview-large-text'>Advertise with the only indoor digital billboard network in Utah</p>
                    {/* TODO: link up chat stuff here */}
                    {/* <button className='advertising-chat-button'>
                        <span>Chat</span>
                        <FaQuestionCircle />
                    </button> */}
                </div>
                <div className='advertise-digiview-image-and-text'>
                    <div className='advertise-digiview-text-and-button'>
                        <p className='advertise-digiview-smaller-text'>digiView’s growing indoor digital billboard network has expansive coverage across Davis and Salt Lake counties, allowing you to reach consumers with disposable income in high-traffic locations. Connect with tens of thousands of your customers every month with your custom, full-motion ad!</p>
                        <Link to='/contact-us'>
                            <button className='advertise-digiview-partner-button'>Contact Us</button>
                        </Link>
                    </div>
                    <img src={locationImg} alt='billboard location' className='advertising-billboard-image' />
                </div>
            </div>
            <div className='advertise-partners-container'>
                <p className='advertise-partners-top-text'>Ad partners receive thousands of ad plays across our growing indoor digital billboard network!</p>
                <img src={demoImg} alt='demo advertisement' className='advertisement-demo-image' />
            </div>
            <div className='advertising-cards-container'>
                <div className='advertising-cards-row'>
                    <div className='advertising-single-card'>
                        <img src={audienceIcon} alt='audience' className='advertising-icons' />
                        <p className='advertising-single-card-title'>Captive Audience</p>
                        <p className='advertising-single-card-subtitle'>Our screen locations boast an average dwell time of over 15 minutes which leaves customers ample opportunity to see and remember your ad.</p>
                    </div>
                    <div className='advertising-single-card'>
                        <img src={receiptIcon} alt='receipt' className='advertising-icons' />
                        <p className='advertising-single-card-title'>Affordable</p>
                        <p className='advertising-single-card-subtitle'>Our streamlined, all-digital model allows us to alter ads remotely, making indoor digital billboards more economical than print ads, newspapers or traditional billboards.</p>
                    </div>
                    <div className='advertising-single-card'>
                        <img src={targetedIcon} alt='target' className='advertising-icons' />
                        <p className='advertising-single-card-title'>Target based</p>
                        <p className='advertising-single-card-subtitle'>Our indoor digital billboard system allows you to target by demographic, lifestyle, shopping behavior, interests, habits, and more!</p>
                    </div>
                </div>
                <div className='advertising-cards-row'>
                    <div className='advertising-single-card'>
                        <img src={thumbsUpIcon} alt='thumbs up' className='advertising-icons' />
                        <p className='advertising-single-card-title'>Memorable</p>
                        <p className='advertising-single-card-subtitle'>Our indoor digital billboard network has one of the highest retention rates of any type of advertising available.</p>
                    </div>
                    <div className='advertising-single-card'>
                        <img src={locationIcon} alt='location' className='advertising-icons' />
                        <p className='advertising-single-card-title'>Geo-targeted</p>
                        <p className='advertising-single-card-subtitle'>Reaching your customers where they work, shop, eat, and play has never been easier than with our indoor digital billboards. Potential customers spend a great deal of time in local businesses, which is where your ads appear.</p>
                    </div>
                    <div className='advertising-single-card'>
                        <img src={supportIcon} alt='target' className='advertising-icons' />
                        <p className='advertising-single-card-title'>Unparalleled support</p>
                        <p className='advertising-single-card-subtitle'>For us, providing excellent customer support is of paramount importance. We are there for you!</p>
                    </div>
                </div>
            </div>
            <div className='locations-map-container'>
                <p className='advertising-locations-text'>digiView Screen Locations:</p>
                <div className='advertising-map-container'>
                    <iframe src="https://www.google.com/maps/d/u/1/embed?mid=1Jgz5YEobCESSD2a-gXXQ5jtGNkUP1u4&amp;ehbc=2E312F" title='locations' style={{ position: 'relative', height: '100%', width: '100%' }} id="iFrameResizer0" scrolling="yes" ></iframe>
                </div>
                <Link to='/contact-us'>
                    <button className='advertising-contact-us-button'>Contact Us</button>
                </Link>
            </div>
            <div className='advertising-page-logo'>
                <img src={digiviewLogo} alt='digiview logo' className='advertising-digiview-footer-logo' />
            </div>
        </div>
    )
}