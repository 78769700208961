import logo from '../../Images/digiView_Logo_Color_Horizontal-new.jpg';
import { IoIosArrowDown } from 'react-icons/io';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import './Navbar.css';
import { useState } from 'react';

export default function Navbar() {

  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const [isOverList, setIsOverList] = useState(false);


  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    if (!isOverList) {
      setOpen(false);
    }
  }

  function handleDropdownClick() {
    setOpen(!open);
  }

  function handleListMouseLeave() {
    setIsOverList(false);
    setOpen(false);
  }

  function selectTab(tabName) {
    setActiveTab(tabName);
  }

  return (
    <div className='navbar'>
      <div className='logo'>
        <Link
         to='/'
         onClick={() => selectTab('')}
        >
          <img src={logo} alt='digiview logo' className='navbar-logo' />
        </Link>
      </div>
      <Link
        to='/hosting'
        onClick={() => selectTab('hosting')}
        className={activeTab === 'hosting' ? 'active navbar-link' : 'navbar-link'}
      >
        Hosting
      </Link>
      <Link
        to='/advertising'
        onClick={() => selectTab('advertising')}
        className={activeTab === 'advertising' ? 'active navbar-link' : 'navbar-link'}
      >
        Advertising
      </Link>
      <span className='dropdown' onMouseEnter={handleOpen} onMouseLeave={handleClose} onClick={handleDropdownClick}>
        <span className='navbar-link'>
          <Link
            to='/digital-marketing-services'
            onClick={() => selectTab('marketing')}
            className={activeTab === 'marketing' ? 'active navbar-link' : 'navbar-link'}
          >
            Digital Marketing Services
          </Link>
          <IoIosArrowDown style={{ fontSize: '1.25rem', paddingLeft: '5px' }} className={activeTab === 'marketing' ? 'active arrow-down' : 'arrow-down'}/>
        </span>
        {open ? (
          <ul className="menu" onMouseEnter={() => setIsOverList(true)} onMouseLeave={handleListMouseLeave}>
            <li className="menu-item">
              <HashLink
                to='/digital-marketing-services#geofencing'
                onClick={() => selectTab('geofencing')}
                className={activeTab === 'geofencing' ? 'active dropdown-link' : 'dropdown-link'}
              >
                Geofencing
              </HashLink>
            </li>
            <li className="menu-item">
              <HashLink
                to='/digital-marketing-services#google-business'
                onClick={() => selectTab('google')}
                className={activeTab === 'google' ? 'active dropdown-link' : 'dropdown-link'}
              >
                Google Business Profile Management
              </HashLink>
            </li>
            <li className="menu-item">
              <HashLink
                to='/digital-marketing-services#social-media'
                onClick={() => selectTab('social')}
                className={activeTab === 'social' ? 'active dropdown-link' : 'dropdown-link'}
              >
                Social Media Management
              </HashLink>
            </li>
            <li className="menu-item">
              <HashLink
                to='/digital-marketing-services#digital-menu'
                onClick={() => selectTab('digital-menu')}
                className={activeTab === 'digital-menu' ? 'active dropdown-link' : 'dropdown-link'}
              >
                Digital Menu Boards
              </HashLink>
            </li>
            <li className="menu-item">
              <HashLink
                to='/digital-marketing-services#web-design'
                onClick={() => selectTab('web-design')}
                className={activeTab === 'web-design' ? 'active dropdown-link' : 'dropdown-link'}
              >
                Website Design
              </HashLink>
            </li>
            <li className="menu-item">
              <HashLink
                to='/digital-marketing-services#graphic-design'
                onClick={() => selectTab('graphic-design')}
                className={activeTab === 'graphic-design' ? 'active dropdown-link' : 'dropdown-link'}
              >
                Graphic Design Services
              </HashLink>
            </li>
          </ul>
        ) : null }
      </span>
      <Link
        to='/screen-locations'
        onClick={() => selectTab('locations')}
        className={activeTab === 'locations' ? 'active navbar-link' : 'navbar-link'}
      >
        Screen Locations
      </Link>
      <Link
        to='/contact-us'
        onClick={() => selectTab('contact')}
        className={activeTab === 'contact' ? 'active navbar-link' : 'navbar-link'}
      >
        Contact Us
      </Link>
    </div>
  )
}
