import './Hosting.css';
import { Link } from 'react-router-dom';
// import { FaQuestionCircle } from 'react-icons/fa'
import locationImg from '../../assets/Hosting-photo-circle.png';
import installImg from '../../assets/Install-photo.png';
import bountifulBike1Img from '../../assets/Demo-image-tv-bbros.png';
import bountifulBike2Img from '../../assets/Demo-image-tv-bbbs.png';
import bannerImg from '../../assets/Hosting-Photo-fullsize-bowling.png';
import audienceIcon from '../../assets/Icon-Audience.png';
import receiptIcon from '../../assets/Icon-receipt.png';
import targetedIcon from '../../assets/Icon-target.png';
import thumbsUpIcon from '../../assets/Icon-thumbsup.png';
import locationIcon from '../../assets/Icon-Map Pin.png';
import supportIcon from '../../assets/Icon-Support.png';
import digiviewLogo from '../../assets/digiView_Logo_Color__Light_Horizontal-new.png';
import { Helmet } from 'react-helmet';

export default function Hosting() {
    return (
        <div className='hosting-container'>
            <Helmet>
                {/* Google Analytics */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-LKZLK9CCHH"></script>
                <script>
                    {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-LKZLK9CCHH');
          `}
                </script>
            </Helmet>
            <div className='host-digiview-container'>
                <div className='host-digiview-text-and-chat'>
                    <p className='host-digiview-large-text'>Host a digiView indoor digital billboard and multiply your ad space</p>
                    {/* TODO: link up chat stuff here */}
                    {/* <button className='hosting-chat-button'>
                        <span>Chat</span>
                        <FaQuestionCircle />
                    </button> */}
                </div>
                <div className='host-digiview-image-and-text'>
                    <img src={locationImg} alt='billboard location' className='billboard-location-image' />
                    <div className='host-digiview-text-and-button'>
                        <p className='host-digiview-smaller-text'>digiView indoor digital billboards are found in many high traffic businesses throughout Davis and Salt Lake counties. As a host your ads will be featured at your own business location as well as in other non-competitive businesses throughout our network.</p>
                        <Link to='/contact-us'>
                            <button className='host-digiview-partner-button'>Become a Host Partner</button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='host-partners-container'>
                <p className='host-partners-top-text'>What our Host Partners get</p>
                <div className='host-partners-text-image'>
                    <img src={bountifulBike1Img} alt='bountiful bicycle' className='bike-image' />
                    <p className='host-partners-text'>A collection of professional, eye-catching, full-motion and still ads specifically designed for your business that will be featured on your indoor digital billboard. Use your billboard to <strong>promote special events, daily specials, high-ticket items, charities and fundraisers.</strong></p>
                </div>
                <div className='host-partners-text-image'>
                    <p className='host-partners-text'>Professionally created advertisements to be seen outside of your business. Host Partners also receive <strong>thousands of ad plays across our growing indoor digital billboard network!</strong></p>
                    <img src={bountifulBike2Img} alt='bountiful bicycle' className='bike-image' />
                </div>
                <div className='host-partners-text-image-install'>
                    <img src={installImg} alt='tv installation' className='install-image' />
                    <p className='host-partners-text'>Become a Host Partner and <strong>we will professionally install all of the necessary equipment for your indoor digital billboard.</strong></p>
                </div>
            </div>
            <div className='banner-img-container'>
                <img src={bannerImg} alt='bowling location' className='banner-image'/>
            </div>
            <div className='hosting-cards-container'>
                <div className='hosting-cards-row'>
                    <div className='hosting-single-card'>
                        <img src={audienceIcon} alt='audience' className='hosting-icons' />
                        <p className='hosting-single-card-title'>Captive Audience</p>
                        <p className='hosting-single-card-subtitle'>Our screen locations boast an average dwell time of over 15 minutes which leaves customers ample opportunity to see and remember your ad.</p>
                    </div>
                    <div className='hosting-single-card'>
                        <img src={receiptIcon} alt='receipt' className='hosting-icons' />
                        <p className='hosting-single-card-title'>Affordable</p>
                        <p className='hosting-single-card-subtitle'>Our streamlined, all-digital model allows us to alter ads remotely, making indoor digital billboards more economical than print ads, newspapers or traditional billboards.</p>
                    </div>
                    <div className='hosting-single-card'>
                        <img src={targetedIcon} alt='target' className='hosting-icons' />
                        <p className='hosting-single-card-title'>Target based</p>
                        <p className='hosting-single-card-subtitle'>Our indoor digital billboard system allows you to target by demographic, lifestyle, shopping behavior, interests, habits, and more!</p>
                    </div>
                </div>
                <div className='hosting-cards-row'>
                    <div className='hosting-single-card'>
                        <img src={thumbsUpIcon} alt='thumbs up' className='hosting-icons' />
                        <p className='hosting-single-card-title'>Memorable</p>
                        <p className='hosting-single-card-subtitle'>Our indoor digital billboard network has one of the highest retention rates of any type of advertising available.</p>
                    </div>
                    <div className='hosting-single-card'>
                        <img src={locationIcon} alt='location' className='hosting-icons' />
                        <p className='hosting-single-card-title'>Geo-targeted</p>
                        <p className='hosting-single-card-subtitle'>Reaching your customers where they work, shop, eat, and play has never been easier than with our indoor digital billboards. Potential customers spend a great deal of time in local businesses, which is where your ads appear.</p>
                    </div>
                    <div className='hosting-single-card'>
                        <img src={supportIcon} alt='target' className='hosting-icons' />
                        <p className='hosting-single-card-title'>Unparalleled support</p>
                        <p className='hosting-single-card-subtitle'>For us, providing excellent customer support is of paramount importance. We are there for you!</p>
                    </div>
                </div>
            </div>
            <div className='locations-map-container'>
                <p className='locations-text'>digiView Screen Locations:</p>
                <div className='hosting-map-container'>
                    <iframe src="https://www.google.com/maps/d/u/1/embed?mid=1Jgz5YEobCESSD2a-gXXQ5jtGNkUP1u4&amp;ehbc=2E312F" title='digiview locations' style={{ position: 'relative', height: '100%', width: '100%' }} id="iFrameResizer0" scrolling="yes" ></iframe>
                </div>
                <Link to='/contact-us'>
                    <button className='host-contact-us-button'>Contact Us</button>
                </Link>
            </div>
            <div className='host-page-logo'>
                <img src={digiviewLogo} alt='digiview logo' className='hosting-digiview-footer-logo' />
            </div>
        </div>
    )
}