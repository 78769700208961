import './ScreenLocations.css';
import { Link } from 'react-router-dom';
// import { FaQuestionCircle } from 'react-icons/fa';
import locationsCollage from '../../assets/Locations-photo-collage.png';
import digiviewLogo from '../../assets/digiView_Logo_Color__Light_Horizontal-new.png';
import { Helmet } from 'react-helmet';

export default function ScreenLocations() {
    return (
        <div className='screen-locations-container'>
            <Helmet>
                {/* Google Analytics */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-LKZLK9CCHH"></script>
                <script>
                    {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-LKZLK9CCHH');
          `}
                </script>
            </Helmet>
            <div className='digiview-screen-locations-container'>
                <div className='digiview-screen-locations-text-and-chat'>
                    <p className='digiview-screen-locations-large-text'>digiView Screen Locations</p>
                    {/* TODO: link up chat stuff here */}
                    {/* <button className='digiview-screen-locations-chat-button'>
                        <span>Chat</span>
                        <FaQuestionCircle />
                    </button> */}
                </div>
                <div className='digiview-screen-locations-text'>
                    <p className='digiview-screen-locations-smaller-text'>Our growing indoor digital billboard network has expansive coverage across Davis and Salt Lake counties allowing you to affordably connect with tens of thousands of your local customers every month.</p>
                </div>
                <div className='digiview-screen-locations-map-container'>
                    <iframe src="https://www.google.com/maps/d/u/1/embed?mid=1Jgz5YEobCESSD2a-gXXQ5jtGNkUP1u4&amp;ehbc=2E312F" title='digiview locations' style={{ position: 'relative', height: '100%', width: '100%' }} id="iFrameResizer0" scrolling="yes" ></iframe>
                </div>
                <Link to='/contact-us'>
                    <button className='digiview-screen-locations-contact-button'>Contact Us</button>
                </Link>
            </div>
            <div className='billboard-in-action-container'>
                <div className='billboard-in-action-text-container'>
                    <p className='billboard-in-action-text'>Our Indoor Billboard network in action</p>
                </div>
                <div className='billboard-in-action-collage-container'>
                    <img src={locationsCollage} alt='billboard locations' className='sl-billboard-collage' />
                </div>
            </div>
            <div className='sl-page-logo'>
                <img src={digiviewLogo} alt='digiview logo' className='sl-digiview-footer-logo' />
            </div>
        </div>
    )
}